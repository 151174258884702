<template>
    <div class="footer">
      <div class="footer__upper-wrapper">
        <FooterSocialMediaButton></FooterSocialMediaButton>
      </div>
      <hr class="footer__line">
      <div class="footer__lower-wrapper">
        <div class="footer__lower-wrapper-left">
          <router-link to="/impressum" class="footer__text">Impressum</router-link>
          <router-link to="/datenschutz" class="footer__text">Datenschutzerklärung</router-link>
          <!-- <a class="footer__text" href="">Erstinformationen</a> -->
        </div>
        
        <div class="footer__lower-wrapper-right">
          <a href="#" class="footer__text" data-cc="c-settings">Cookie-Einstellungen ändern</a>
          <!-- <a class="footer__text" href="">AGB</a>
          <a class="footer__text" href="">Pixel</a> -->
        </div>
      </div>
      <hr class="footer__line">
      <span class="footer__contact-space"></span>
    </div>
    <div class="footer__contact">
      <div class="footer__contact-left">
        <router-link class="header__icon-wrapper" to="/">
          <img class="footer__icon" src="@\assets\images\fairmoegenskompass-logo-white-large.png" alt="Fairmögenskompass-Logo">
        </router-link>
      </div>
      <div class="footer__contact-right">
        <p class="footer__contact-text">Copyright © 2023, Fairmögenskompass</p>
      </div>
    </div>
  </template>
  
  
  <script>
  import variables from '@/assets/variables.less';
  import config from '@/../config.js';
  import pagesData from '@/assets/pagesData.js';
  import FooterSocialMediaButton from './FooterSocialMediaButton.vue'
  
  
  export default {
    name: 'main-header',
    components: {
      FooterSocialMediaButton,
  },
    data () {
      return {
        variables: variables,
        domain: config.domain,
        pagesData,
      };
    },
  };
  </script>
  
  
  <style scoped lang="less">
  @import '@/assets/variables.less';
  .footer {
    position: relative;
    z-index: 998;
    display: flex;
    flex-direction: column;
    background-color: @fmk-dark-green;
    color: @fmk-white;
    width: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: 0rem 0rem 1.5rem 0.15rem @fmk-shadow;
    bottom: 0;
    left: 0;
    &__text {
      display: block;
      color: @fmk-white;
      text-decoration: none;
    }
    &__upper-wrapper {
      display: flex;
      padding-inline: 1rem;
      padding-top: 1rem;
      padding-bottom: 0.3rem;
      gap: 2rem;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

    }
    &__lower-wrapper {
      display: flex;
      padding: 1rem;
      column-gap: 1rem;
      width: 85%;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      &-left, &-right {
        @media (max-width: @breakpoint-medium) {
          margin-left: 1rem;
          width: 100%;
        }
      }
    }
    &__contact {
      background-color: @fmk-black;
      padding-inline: 3rem;
      padding-block: 0.5rem;
      display: flex;
      column-gap: 1rem;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 999;
      @media (max-width: @breakpoint-medium) {
        padding-inline: 0.5rem;
        justify-content: end;
        padding-bottom: 0.6rem;
      }
    }
    &__contact-left {
      @media (max-width: @breakpoint-medium) {
        display: none;
      }
    }
    &__contact-text {
      font-size: @font-size-6;
      color: @fmk-white--transparent;
      display: unset;
    }
    &__contact-space {
      height: 2rem;
      @media (min-width: @breakpoint-medium) {
        height: 4rem;
      }
    }
    &__icon {
      height: 1.4rem;
      opacity: 0.6;
      margin-top: 0.3rem;
    }
    &__line {
      width: 100%;
      border-top: 0.03125rem solid @fmk-white--transparent;
      border-bottom: none;
      border-inline: none;
      border-radius: 50;
      max-width: calc(@max-screen-width - 3rem);
    }
  }
  
  </style>
  