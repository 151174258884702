import { createRouter, createWebHistory } from 'vue-router';
import pagesData from './assets/pagesData';
const NotFoundComponent = () => import('@/components/pages/NotFoundComponent.vue');

// Basis-URL entsprechend dem Deployment-Pfad auf Netlify
const baseURL = process.env.BASE_URL || '/';

const routes = pagesData.pages.map(page => ({
  path: page.path,
  name: page.name,
  component: page.component,
}));

// Fallback-Route für unbekannte Pfade
routes.push({ path: '/:pathMatch(.*)*', component: NotFoundComponent });

const router = createRouter({
  history: createWebHistory(baseURL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Immer an den Seitenanfang springen
    return { top: 0, left: 0 };
  }
});

export default router;
